class LifeVineSettings {
    private lv: LifeVine;
    private basePath: string;

    constructor(lv: LifeVine) {
        this.lv = lv;
        this.basePath = '/settings';
    }

    public find(name: string) {
        return this.lv.ajax('GET', this.getPath(), {name: name});
    }

    public get(id?: Number) {
        return this.lv.ajax('GET', this.getPath(id));
    }

    public save(id: Number, value) {
        return this.lv.ajax('PUT', this.getPath(id), {value: value});
    }

    private getPath(id?: any) {
        return id !== undefined ? `${this.basePath}/${id}` : this.basePath;
    }
}